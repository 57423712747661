const initialState = {
  percent: '',
  title: '',
  text: '',
  pageNumber: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'PERCENT':
      return { 
        ...state,
        percent: action.payload.percent
      }
    case 'TEXT':
      return { 
        ...state,
        text: action.payload.text
      }
    case 'TITLE':
      return { 
        ...state,
        title: action.payload.title
      }
    case 'PAGE_NUMBER':
      return { 
        ...state,
        pageNumber: action.payload.pageNumber
      }
    default:
      return state;
    }
};