import { combineReducers } from 'redux';
import formReducer from './formReducer';
import pageReducer from './pageReducer'
import metaReducer from './metaReducer';
import pageDataReducer from './pageData';
export default combineReducers({ 
  formState: formReducer,
  page: pageReducer,
  meta: metaReducer,
  pageData: pageDataReducer
});