const initialValues = {
  // household_size: '',
  // life_event: '',
  // existing_conditions: '',
  // expectant_parent: '0',
  // previously_denied: '0',
  // currently_insured: '1',
  // weight: '160',
  // height_inches: '7',
  // height_feet: '5',
  // key: 'def',
  // session_id: '',
  // smoker: '',
  // income: '',
  // dob: '',
  // trusted_form_url: '',
  gender: '',
  dateOfBirth: '',
  name_first: '',
  name_last: '',
  currently_insured: '',
  home_street: '',
  home_zip: '',
  home_state: '',
  home_city: '',
  email: '',
  phone_home: '',
  us_state: '',
}

export default (state = initialValues, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      const {name, value} = action.payload
      return { 
        ...state, [name]: value 
      }
    case 'SET_HOME_CITY_STATE':
      return {
        ...state,
        home_city: action.payload.home_city,
        home_state: action.payload.home_state,
        us_state: action.payload.us_state
      }
    case 'CLEAR_HOME_CITY_STATE':
      return {
        ...state,
        home_city: action.payload.home_city,
        home_state: action.payload.home_state
      }
    default:
      return state;
    }
};